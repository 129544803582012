import { render, staticRenderFns } from "./NovaReceitaDialog.vue?vue&type=template&id=07ea6d6e&scoped=true&"
import script from "./NovaReceitaDialog.vue?vue&type=script&lang=js&"
export * from "./NovaReceitaDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ea6d6e",
  null
  
)

export default component.exports