<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="800px">
        <v-card v-if="dialog">
            <v-card-title>
                Arquivos
                <v-btn class="ml-2" rounded x-small color="primary"
                       depressed
                       @click.stop="onAdicionarArquivosButtonClicked">
                    Adicionar +
                </v-btn>
                <input v-show="false" ref="inputFileUpload" type="file" multiple
                       @input="adicionarArquivos" >
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-simple-table v-if="arquivos.length > 0" class="custom-table">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Nome</th>
                                <th style="width: 100%">Tags</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(arquivo, index) in arquivos" :key="index">
                                <td class="px-0">
                                    <v-btn icon color="red" small @click="arquivos.splice(index, 1)"><v-icon small>mdi-close</v-icon></v-btn>
                                </td>
                                <td style="max-width: 300px; text-overflow: ellipsis; overflow: hidden" class="text-no-wrap">
                                    <span class="caption font-weight-light text-no-wrap" :title="arquivo.file.name" >{{arquivo.file.name}}</span>
                                </td>
                                <td>
                                    <v-autocomplete v-model="arquivo.tags" :items="tags"
                                            class="tags-input"
                                            dense hide-details chips small-chips
                                            multiple outlined deletable-chips clearable
                                            item-text="nome" return-object
                                            :rules="arquivo.isInvalid ? [] : [rules.required]" required
                                            :disabled="arquivo.isInvalid"
                                    ></v-autocomplete>
                                </td>
                                <td>
                                    <v-icon v-if="arquivo.status === 1">mdi-upload</v-icon>
                                    <v-icon v-if="arquivo.status === 2" color="green">mdi-check-circle</v-icon>
                                    <v-tooltip v-if="arquivo.isInvalid || arquivo.status === 3"
                                               bottom color="red" max-width="300px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon small v-on="on" color="red">
                                                mdi-alert
                                            </v-icon>
                                        </template>
                                        <span>{{arquivo.errorMessage}}</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div v-else class="text-center py-6">
                        <span class="subtitle-1">Nenhum arquivo para ser enviado</span>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" :disabled="arquivosValidos < 1" @click="ok">
                            Ok
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import gql from "graphql-tag";

    export default {
        name: "DefinirArquivosTagsDialog",
        data() {
            return {
                dialog: false,
                loading: false,
                tags: [],
                arquivos: [],
                despesa: null,
                form1Valid: true,
                //totalEnviado: 0,
                editMode: false,
                rules: {
                    required: (v) => {
                        return (!!v && v.length > 0) || 'Campo Obrigatório.'
                    },
                },
            }
        },
        computed:{
            arquivosValidos(){
                return this.arquivos.filter((arquivo) => !arquivo.isInvalid)
            }
        },
        methods: {
            openDialog(files, despesa) {
                this.dialog = true;
                this.despesa = despesa;
                this.getTags();
                this.mapFiles(files);

            },
            closeDialog() {
                this.dialog = false;
                //this.totalEnviado = 0;
                this.arquivos = [];
                //TODO Cancelar as requisiçoes com https://stackoverflow.com/questions/50516438/cancel-previous-request-using-axios-with-vue-js
            },
            onAdicionarArquivosButtonClicked(){
                this.$refs.inputFileUpload.value = "";
                this.$refs.inputFileUpload.click();
            },
            adicionarArquivos(e){
                this.mapFiles(e.target.files);
            },
            mapFiles(files){
                for (let i = 0; i < files.length; i++) {
                    let encontrou = this.arquivos.find(arquivo => arquivo.file.name === files[i].name);
                    if(encontrou === undefined){
                        let arquivo = {
                            nome: files[i].name,
                            file: files[i],
                            status: 0,
                            isInvalid: false,
                            tags:[]
                        }
                        if(arquivo.file.size > 8000000){
                            arquivo.isInvalid = true;
                            arquivo.errorMessage = "Arquivo muito grande";
                        }
                        this.arquivos.push(arquivo);
                    }

                }
            },
            getTags(){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query($categoria:String!) {
                        tags:tagsPorTipo(categoria: $categoria){
                            id
                            nome
                        }
                    }`,
                    variables: {
                        categoria: 'despesa'
                    }
                }).then((result) => {
                    this.tags = result.data.tags;
                }).finally(()=>{
                    this.loading = false;
                })
            },
            ok(){
                if(!this.$refs.form1.validate()){
                    return
                }

                this.$emit('completed', this.arquivos);
                this.closeDialog();
            },

        }
    }
</script>

<style>
    .custom-table td{
        height: 30px;
    }
    .tags-input{
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }
    .tags-input .v-input__control .v-input__slot {
        min-height: auto !important;
        display: flex !important;
        align-items: center !important;
    }
</style>