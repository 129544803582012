<template>
    <v-dialog v-model="dialog" scrollable persistent width="500px">
        <v-card v-if="dialog">
            <v-card-title>{{editMode ? 'Editar' : 'Nova'}} Despesa</v-card-title>
            <v-card-text class="pb-0">
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row no-gutters>
                        <v-col cols="6" class="py-0">
                            <v-autocomplete v-model="despesa.item" label="Item"
                                            placeholder=" " :items="itensFiltrados"
                                            item-text="nome" return-object clearable
                                            :rules="requiredRules">
                                <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                        <div class="caption">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-text-field v-model="despesa.descricao" label="Descrição" clearable
                                          placeholder=" " maxlength="200"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-text-field label="Valor" prefix="R$" placeholder=" " ref="valorTotalGarantias"
                                          v-model="despesa.valor"
                                          v-money="money"
                                          :rules="requiredRulesValor" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters style="background-color: whitesmoke;">
                        <v-subheader style="width: 100%" class="text-uppercase caption">
                            <span class="blue-grey--text">Anexos</span>

                            <v-btn icon x-small color="blue-grey darken-1" class="ml-1"
                                   @click.stop="onAdicionarArquivosButtonClicked">
                                <v-icon small>mdi-plus-circle</v-icon>
                                <template v-slot:loader>
                                    <v-progress-circular
                                        indeterminate width="2" size="14"
                                        color="blue-grey "
                                    ></v-progress-circular>
                                </template>
                            </v-btn>
                            <input v-show="false" ref="inputFileUpload" type="file" multiple
                                   @input="uploadFileManually" >
                        </v-subheader>
                        <v-list style="width: 100%" dense class="pa-0" color="transparent" v-if="despesa.arquivos.length > 0">
                            <template v-for="(arquivo, index) in despesa.arquivos">
                                <v-hover :key="index" v-slot:default="{ hover }">
                                    <v-list-item :key="index" >
                                        <v-list-item-avatar class="my-0" >
                                            <v-icon :style="'opacity: '+(hover?'1':'0.6')"
                                                    :color="getIconColor(arquivo.nome)">
                                                {{getIcon(arquivo.nome)}}
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content class="py-2">
                                            <v-list-item-title class="font-weight-regular"  :title="arquivo.nome">
                                                {{arquivo.nome}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle >
                                                <template v-for="tag in arquivo.tags">
                                                    <v-chip :key="tag.id" x-small label color="blue" text-color="white"
                                                            :title="arquivo.tags.map(t => t.nome).join(', ')"
                                                            class="mr-1 px-2" style="height: 14px; font-size: 10px">
                                                        {{tag.nome}}
                                                    </v-chip>
                                                </template>

                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="font-weight-regular" style="font-size: 10px">
                                                <span v-if="arquivo.createdAt">Criado em {{arquivo.createdAt | moment('DD-MM-YYYY [às] HH:mm')}}</span>
                                                <span v-else>Arquivo não salvo ainda</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action class="my-0" v-if="hover" title="">
                                            <div>
                                                <v-btn icon color="red" dark
                                                       @mousedown.stop @touchstart.native.stop
                                                       @click.stop="removeArquivo(arquivo, index)">
                                                    <v-icon small>mdi-delete</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-hover>
                            </template>
                        </v-list>
                        <div v-else class="body-2 text-center pa-2 blue-grey--text lighten-2" style="width: 100%">
                            <span class="overline font-weight-medium">Nenhuma arquivo adicionado.</span><br/>
                        </div>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="save" >
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <definir-arquivos-tags-dialog ref="definirArquivosTagsDialog" @completed="onArquuivosAdicionados" />
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import DefinirArquivosTagsDialog from "../financeiro/DefinirArquivosTagsDialog";
import {getExtensionFile, getIcon, getIconColor} from "../../assets/FileUtils";
import numeral from "numeral";
export default {
    name: "NovaDespesaDialog",
    components: {DefinirArquivosTagsDialog},
    data() {
        return {
            dialog: false,
            loading: false,
            form1Valid: true,
            hasErrors: false,
            totalEnviado: 0,
            editMode: false,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            requiredRulesValor: [
                (v) => this.convertMoneyToFloat(v)>0 || 'Campo obrigatório',
            ],
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            categorias: [],
            despesa: {
                item: null,
                descricao: null,
                valor: null,
                //formaPagamento: null,
                arquivos: [],
            },
            totalArquivosParaEnviar: 0,
        }
    },
    watch:{
        totalEnviado(value){
            if(this.loading && this.totalArquivosParaEnviar === value){
                if(!this.hasErrors){
                    this.$notify({text: 'Arquivos enviados com sucesso!', type: 'success'});
                    let valor = this.convertMoneyToFloat(this.despesa.valor);
                    if(valor > 0){
                        valor = valor * -1
                    }
                    this.despesa.valor = valor
                    this.$emit('saved', this.despesa, this.editMode);
                    this.loading = false;
                    this.closeDialog();
                }else{
                    this.totalEnviado = 0;
                    this.$notify({text: 'Algumas arquivos falharam ao serem enviados!', type: 'error'});
                    this.loading = false;
                }
            }
        }
    },
    computed: {
        arquivosNovos(){
            return this.despesa.arquivos.filter(a => a.id === undefined);
        },
        itensFiltrados(){
            let itens = [];

            for(let categoria of this.categorias){
                itens.push({header: categoria.nome})
                for(let item of categoria.itens){
                    itens.push(item)
                }
                itens.push({ divider: true })
            }

            return itens;
        }
    },
    methods: {
        getIcon, getIconColor, getExtensionFile,
        openDialog(despesa) {
            this.dialog = true;
            this.despesa = {
                item: null,
                descricao: null,
                valor: null,
                //formaPagamento: null,
                arquivos: [],
            }
            this.editMode = false;
            if(despesa){
                this.editMode = true;
                this.despesa = this._.cloneDeep(despesa);

                if(this.despesa.valor < 0){
                    this.despesa.valor = this.despesa.valor * -1
                }
                this.despesa.valor = numeral(this.despesa.valor).format('0.00').replace(',','.');
            }
            this.getItens();
        },
        closeDialog() {
            this.dialog = false;
            this.totalEnviado = 0;
            this.totalArquivosParaEnviar = 0
        },
        onAdicionarArquivosButtonClicked(){
            this.$refs.inputFileUpload.value = "";
            this.$refs.inputFileUpload.click();
        },
        uploadFileManually(e){
            this.$refs.definirArquivosTagsDialog.openDialog(e.target.files, this.fiscalizacao);
        },
        onArquuivosAdicionados(arquivos){
            this.totalArquivosParaEnviar += arquivos.length
            this.despesa.arquivos = this.despesa.arquivos.concat(arquivos)
        },
        removeArquivo(arquivo, index){
            if(arquivo.id){
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar este arquivo?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: gql`mutation($id: Int!, $arquivoId: Int!) {
                                deleteMovimentacaoArquivo(id: $id, arquivoId: $arquivoId)
                            }`,
                            variables: {
                                id: this.despesa.id,
                                arquivoId: arquivo.id
                            },
                        }).then(() => {
                            this.despesa.arquivos.splice(index, 1);
                        })
                    }
                });

            }else{
                this.despesa.arquivos.splice(index, 1);
                this.totalArquivosParaEnviar--
            }
        },
        save() {
            if (!this.$refs.form1.validate()) {
                return false
            }
            this.loading = true;

            if(!this.editMode){
                this.$apollo.mutate({
                    mutation: gql`mutation($itemId: Int!, $descricao: String, $valor: BigDecimal!) {
                        despesa:addDespesa(itemId: $itemId, descricao: $descricao, valor: $valor){
                            id
                            item{
                                id
                                nome
                                categoria{
                                    id
                                    nome
                                    tipo{
                                        id
                                    }
                                }
                            }
                            arquivos{
                                id
                                nome
                                createdAt
                                tags{
                                    nome
                                }
                            }
                            descricao
                            valor
                            createdAt
                        }
                    }`,
                    variables: {
                        itemId: this.despesa.item.id,
                        descricao: this.despesa.descricao,
                        valor: this.convertMoneyToFloat(this.despesa.valor),
                    },
                }).then((result) => {
                    let despesaSalva = result.data.despesa;
                    delete despesaSalva.arquivos;
                    this.despesa = Object.assign(this.despesa, despesaSalva);
                    if(this.arquivosNovos.length > 0){
                        this.uploadFiles(this.arquivosNovos);
                    }else{
                        this.loading = false
                        this.closeDialog();
                        this.$emit("saved", this.despesa, this.editMode);
                    }
                })
            }else{
                this.$apollo.mutate({
                    mutation: gql`mutation($id: Int!, $itemId: Int!, $descricao: String, $valor: BigDecimal!) {
                        despesa:editarDespesa(id: $id, itemId: $itemId, descricao: $descricao, valor: $valor){
                            id
                            item{
                                id
                                nome
                                categoria{
                                    id
                                    nome
                                    tipo{
                                        id
                                    }
                                }
                            }
                            arquivos{
                                id
                                nome
                                createdAt
                                tags{
                                    nome
                                }
                            }
                            descricao
                            valor
                            createdAt
                        }
                    }`,
                    variables: {
                        id: this.despesa.id,
                        itemId: this.despesa.item.id,
                        descricao: this.despesa.descricao,
                        valor: this.convertMoneyToFloat(this.despesa.valor),
                    },
                }).then((result) => {
                    let despesaSalva = result.data.despesa;
                    delete despesaSalva.arquivos;
                    this.despesa = Object.assign(this.despesa, despesaSalva);
                    if(this.arquivosNovos.length > 0){
                        this.uploadFiles(this.arquivosNovos);
                    }else{
                        this.loading = false
                        this.closeDialog();
                        this.$emit("saved", this.despesa, this.editMode);
                    }
                })
            }


        },
        getItens(){
            this.loading = true;
            this.$apollo.query({
                query : gql`query {
                    categorias:categoriasDespesa{
                        id
                        nome
                        itens{
                            id
                            nome
                            descricao
                        }
                    }
                }`,
            }).then((result) => {
                this.categorias = result.data.categorias;
            }).finally(()=>{
                this.loading = false
            });
        },
        uploadFiles(arquivos){
            this.hasErrors = false;
            //for (let arquivo of this.arquivosValidos) {
            for (let arquivo of arquivos) {
                this.uploadFile(arquivo)
            }
        },
        uploadFile(arquivo){
            arquivo.status = 1;
            const formData = new FormData();
            formData.append('file', arquivo.file);
            formData.append('tags', arquivo.tags.map(t => t.id));
            this.$axios.post('/financeiro/movimentacao/' + this.despesa.id + '/uploadFile', formData, {
                headers: {
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
            }).then((result) => {
                arquivo = result.data
                arquivo.status = 2;
                let arquivoIndex = this.despesa.arquivos.findIndex(a => a.nome === arquivo.nome);
                this.$set(this.despesa.arquivos, arquivoIndex, arquivo)
            }).catch((error) => {
                this.hasErrors = true;
                arquivo.status = 3;
                arquivo.errorMessage = error.response.data.message;
            }).finally(()=>{
                this.totalEnviado++;
            })
        },
        convertMoneyToFloat(value) {
            if (!isNaN(value)) {
                return value;
            }
            return parseFloat(value.replace(/\./g, '').replace(',', '.'));
        },
    }
}
</script>

<style scoped>

</style>