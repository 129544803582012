<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="1200px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Movimentação</span>
                <v-btn rounded small color="primary" class="ml-3 mb-1" v-if="movimentacao && canEdit"
                       @click="editarMovimentacao(movimentacao)">Editar</v-btn>
            </v-card-title>
            <v-card-text class="py-0" v-if="movimentacao">
                <div>
                    <v-row>
                        <v-col class="py-0">
                            <v-row no-gutters>
                                <v-col>
                                    <span class="d-block caption font-weight-medium">Item</span>
                                    <span>{{movimentacao.item.nome}}</span>
                                </v-col>
                                <v-col>
                                    <span class="d-block caption font-weight-medium">Categoria</span>
                                    <span>{{movimentacao.item.categoria.nome}}</span>
                                </v-col>
                                <v-col>
                                    <span class="d-block caption font-weight-medium">Data Criação</span>
                                    <span>{{movimentacao.createdAt | moment('DD/MM/YYYY HH:mm')}}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col>
                                    <span class="d-block caption font-weight-medium">Descrição</span>
                                    <span>{{movimentacao.descricao}}</span>
                                </v-col>
                                <v-col>
                                    <span class="d-block caption font-weight-medium">Valor</span>
                                    <span :class="movimentacao.valor > 0 ? 'indigo--text' : 'red--text'">
                                {{ movimentacao.valor | formatNumber }}
                            </span>
                                </v-col>
                                <v-col>
                                    <span class="d-block caption font-weight-medium">Caixa</span>
                                    <span v-if="movimentacao.fechamento">{{ movimentacao.fechamento.nome }}</span>
                                    <span v-else class="green--text">Aberto</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2" style="background-color: whitesmoke;">
                                <v-col>
                                    <v-subheader  class="text-uppercase caption flex" style="align-items: center">
                                        <span class="blue-grey--text">Pagamento</span>
                                        <v-btn v-if="movimentacao.pagamento && canEdit" rounded depressed x-small color="primary" class="ml-2"
                                               @click="efetuarPagamento(movimentacao)">Editar</v-btn>
                                    </v-subheader>
                                    <v-container class="pt-0">
                                        <template v-if="movimentacao.pagamento">
                                            <v-row no-gutters>
                                                <v-col>
                                                    <span class="d-block caption font-weight-medium">Forma de Pagamento</span>
                                                    <span>
                                            {{ movimentacao.pagamento.formaPagamento.nome }}
                                        </span>
                                                </v-col>
                                                <v-col>
                                                    <span class="d-block caption font-weight-medium">Data de Pagamento</span>
                                                    <span>
                                            {{ movimentacao.pagamento.dataPagamento | moment('DD/MM/YYYY') }}
                                        </span>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters  class="mt-2" style="background-color: #ffffff;">
                                                <v-col>
                                                    <v-subheader  class="text-uppercase caption flex" style="align-items: flex-end; height: 30px">
                                                        <span class="blue-grey--text">Transações</span>
                                                        <v-btn rounded depressed x-small v-if="canEdit" color="primary" class="ml-2"
                                                               @click="">Adicionar</v-btn>
                                                    </v-subheader>
                                                    <v-row no-gutters>
                                                        <v-col>
                                                            <template v-if="movimentacao.pagamento.ofxTransactions && movimentacao.pagamento.ofxTransactions.length > 0">
                                                                <v-simple-table fixed-header height="190">
                                                                    <template v-slot:default>
                                                                        <thead>
                                                                        <tr style="white-space: nowrap">
                                                                            <th class="text-left">Data</th>
                                                                            <th class="text-left">Lançamento</th>
                                                                            <th class="text-left">Dcto.</th>
                                                                            <th class="text-left" >Valor</th>
                                                                            <th class="text-left" v-if="canEdit"></th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-for="(transacao, index) in movimentacao.pagamento.ofxTransactions">
                                                                            <tr :key="transacao.id" :class="transacao.ignored ? 'ignored' : ''" style="white-space: nowrap">
                                                                                <td class="grey--text darken-3 caption">
                                                                                    <span>{{ transacao.dtposted | moment("DD/MM/YYYY") }}</span>
                                                                                </td>
                                                                                <td class="caption">{{transacao.memo}}</td>
                                                                                <td class="caption">{{transacao.checknum}}</td>
                                                                                <td :class="transacao.trnamt > 0 ? 'indigo--text' : 'red--text'">
                                                                                    <span class="caption">{{transacao.trnamt | formatNumber}}</span>
                                                                                </td>
                                                                                <td v-if="canEdit">
                                                                                    <v-btn title="Remover Transação"
                                                                                           @click="removerTransacao(transacao)"
                                                                                           icon x-small class="ml-1">
                                                                                        <v-icon color="red" small>mdi-close</v-icon>
                                                                                    </v-btn>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>
                                                                <v-row no-gutters class="pt-2 px-4" style="background-color: whitesmoke">
                                                                    <v-col>{{movimentacao.pagamento.ofxTransactions.length}} itens</v-col>
                                                                    <v-spacer/>
                                                                    <v-col class="shrink">
                                                                <span class="font-weight-light" style="white-space: nowrap">
                                                                    Valor Total <span :class="(totalTransacoes > 0 ? 'indigo--text' : 'red--text') + ' font-weight-medium'">{{totalTransacoes | formatNumber}}</span>
                                                                </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>

                                                            <v-alert color="yellow" dense class="mx-4 mt-4" icon="mdi-alert" v-else>
                                                                Nenhuma transação vinculada a esse pagamento.
                                                            </v-alert>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                        </template>
                                        <v-alert color="yellow" dense class="py-0 my-0" v-else>
                                            <v-row align="center">
                                                <v-col class="shrink">
                                                    <v-icon>
                                                        mdi-alert
                                                    </v-icon>
                                                </v-col>
                                                <v-col class="grow">
                                                    <span>Nenhum pagamento efetuado ainda.</span>
                                                </v-col>
                                                <v-col class="shrink text-right d-flex" v-if="canEdit">
                                                    <v-btn elevation="1" small @click="efetuarPagamento(movimentacao)">Lançar Pagamento</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </v-container>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="shrink pa-0" style="min-width: 300px; background-color: whitesmoke;">
                            <v-subheader  class="text-uppercase caption flex" style="align-items: center">
                                <span class="blue-grey--text">Anexos</span>
                            </v-subheader>
                            <v-list v-if="anexos.length > 0" dense class="pa-0" color="transparent">
                                <template v-for="(arquivo, index) in anexos">
                                    <v-list-item dense :key="index" @click="downloadArquivo(arquivo.id)" >
                                        <v-list-item-avatar class="my-0" >
                                            <v-icon
                                                    :color="getIconColor(arquivo.nome)">
                                                {{getIcon(arquivo.nome)}}
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-2">
                                            <v-list-item-title class="font-weight-regular"  :title="arquivo.nome">
                                                {{arquivo.nome}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle >
                                                <template v-for="tag in arquivo.tags">
                                                    <v-chip x-small label color="blue" text-color="white"
                                                            :title="arquivo.tags.map(t => t.nome).join(', ')"
                                                            class="mr-1 px-2" style="height: 14px; font-size: 10px">
                                                        {{tag.nome}}
                                                    </v-chip>
                                                </template>

                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="font-weight-regular" style="font-size: 10px">
                                                <span v-if="arquivo.createdAt">Criado em {{arquivo.createdAt | moment('DD-MM-YYYY [às] HH:mm')}}</span>
                                                <span v-else>Arquivo não salvo ainda</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action class="my-0" title="">
                                            <div>
                                                <v-btn icon
                                                       v-if="canViewFile(arquivo.nome)"
                                                       @click.stop="viewArquivo(arquivo.id)">
                                                    <v-icon small>mdi-eye</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-list>
                            <div v-else class="body-2 text-center pa-2 mx-4 mt-2 blue-grey--text lighten-2">
                                <span class="overline font-weight-medium">Nenhuma arquivo adicionado.</span>
                            </div>
                        </v-col>
                    </v-row>

                </div>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="closeDialog" >
                            Ok
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
        <nova-despesa-dialog ref="novaDespesaDialog" @saved="onMovimentacaoSaved" />
        <nova-receita-dialog ref="novaReceitaDialog" @saved="onMovimentacaoSaved" />
        <efetuar-pagamento-dialog ref="efetuarPagamentoDialog" @saved="onPagamentoSaved" @desfeito="onPagamentoDesfeito" />
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import EfetuarPagamentoDialog from "@/views/financeiro/EfetuarPagamentoDialog";
import NovaReceitaDialog from "@/views/financeiro/NovaReceitaDialog";
import NovaDespesaDialog from "@/views/financeiro/NovaDespesaDialog";
import {getExtensionFile, getIcon, getIconColor, canViewFile} from "@/assets/FileUtils";

export default {
    name: "ViewMovimentacaoDialog",
    components: {
        EfetuarPagamentoDialog,
        NovaReceitaDialog,
        NovaDespesaDialog,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            movimentacao: null,
            canEdit: false,
        }
    },
    computed:{
        totalTransacoes(){
            let total = 0;
            this.movimentacao.pagamento.ofxTransactions.forEach(t => {
                total += t.trnamt;
            })
            return total;
        },
        anexos(){
            let arquivos = [];
            arquivos = arquivos.concat(this.movimentacao.arquivos)
            if(this.movimentacao.pagamento){
                arquivos = arquivos.concat(this.movimentacao.pagamento.arquivos)
            }
            return arquivos;
        },
    },
    methods: {
        getIcon, getIconColor, getExtensionFile, canViewFile,
        openDialog(movimentacaoId, canEdit = false) {
            this.dialog = true;
            this.canEdit = canEdit;
            this.movimentacao = null;
            this.getMovimentacao(movimentacaoId);
        },
        closeDialog() {
            this.dialog = false;
        },
        getMovimentacao(id){
            this.loading = true;
            this.$apollo.query({
                query : gql`query($id: Int!) {
                    movimentacao(id: $id){
                        id
                        descricao
                        valor
                        createdAt
                        fechamento{
                            id
                            nome
                        }
                        item{
                            id
                            nome
                            categoria{
                                id
                                nome
                                tipo{
                                    id
                                }
                            }
                        }
                        pagamento{
                            id
                            dataPagamento
                            formaPagamento{
                                id
                                nome
                            }
                            arquivos{
                                id
                                nome
                                createdAt
                                tags{
                                    id
                                    nome
                                }
                            }
                            ofxTransactions{
                                id
                                checknum
                                dtposted
                                trnamt
                                memo
                            }
                        }
                        arquivos{
                            id
                            nome
                            createdAt
                            tags{
                                id
                                nome
                            }
                        }
                    }
                }`,
                variables: {
                    id
                }
            }).then((result) => {
                this.movimentacao = result.data.movimentacao;
            }).finally(()=>{
                this.loading = false;
            });
        },
        editarMovimentacao(movimento){
            if(movimento.item.categoria.id === 8){
                this.$refs.novaReceitaDialog.openDialog(movimento);
            }else{
                this.$refs.novaDespesaDialog.openDialog(movimento);
            }
        },
        onMovimentacaoSaved(movimentacao, editMode){
            this.movimentacao = movimentacao;
            this.$emit('edited', this.movimentacao);
        },
        novaReceita(){
            this.$refs.novaReceitaDialog.openDialog();
        },
        viewMovimentacao(movimento){
            this.$refs.viewMovimentacaoDialog.openDialog(movimento.id);
        },
        efetuarPagamento(movimento){
            this.$refs.efetuarPagamentoDialog.openDialog(movimento);
        },
        onPagamentoSaved(pagamento){
            this.movimentacao.pagamento = pagamento;
            this.$emit('edited', this.movimentacao);
        },
        onPagamentoDesfeito(movimento){
            this.movimentacao.pagamento = null;
            this.$emit('edited', this.movimentacao);
        },
        viewArquivo(id){
            this.$axios.get('/financeiro/arquivo/' + id, {responseType: 'blob'}).then((response) => {
                const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                let extension = this.getExtensionFile(fileName);
                let options = {};
                switch (extension) {
                    case 'pdf':
                        options.type = 'application/pdf';
                        break
                    case 'jpg':
                        options.type = 'image/jpg';
                        break
                }
                let blob = new Blob([response.data], options)
                let fileURL = URL.createObjectURL(blob);
                window.open(fileURL)
            }).catch(() => {
                this.$dialog.error({
                    text: 'Não foi possível abrir o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
        downloadArquivo(id) {
            this.$axios.get('/financeiro/arquivo/' + id, {responseType: 'blob'}).then((response) => {
                const fileLink = document.createElement('a');
                fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(() => {
                this.$dialog.error({
                    text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
        removerTransacao(transacao){
            this.$dialog.confirm({
                text: 'Tem certeza que remover essa transação da movimentação?',
                title: 'Atenção',
                actions: {
                    false: 'Cancelar', yes: {text: 'Sim', color: 'primary',},
                }
            }).then((result) => {
                if(result){
                    this.$apollo.mutate({
                        mutation: gql`mutation($transacaoId: Int!) {
                            removeMovimentacaoDaTransacao(transacaoId: $transacaoId)
                        }`,
                        variables: {
                            transacaoId: transacao.id
                        },
                    }).then((result) => {
                        if(result.data.removeMovimentacaoDaTransacao){
                            let index = this.movimentacao.pagamento.ofxTransactions.findIndex(t => t.id === transacao.id);
                            this.movimentacao.pagamento.ofxTransactions.splice(index, 1)
                            this.$emit('edited', this.movimentacao);
                        }
                    })
                }

            })
        }
    }
}
</script>

<style scoped>

</style>