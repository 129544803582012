<template>
    <v-dialog v-model="dialog" scrollable persistent width="800px">
        <v-card v-if="dialog">
            <v-card-title>Notas Fiscais</v-card-title>
            <v-card-text>
                {{notasFiscais.length}}
                <v-list two-line v-if="notasFiscais && notasFiscais.length > 0">
                    <template v-for="(notaFiscal, index) in notasFiscais">
                        <v-list-item dense style="min-height: 51px">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span>{{notaFiscal.numero}}</span>
                                    <span class="font-weight-light"> - {{notaFiscal.discriminacao}}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="d-flex">
                                    Valor Nota <b class="black--text ml-1">{{notaFiscal.valorServicos | formatNumber}}</b>
                                    <template v-if="notaFiscal.dataPagamento">
                                        <div class="ml-3">
                                            Valor Pago <b class="green--text">{{notaFiscal.valorPago | formatNumber}}</b>
                                        </div>
                                        <div class="ml-3">
                                            Descontado <b class="red--text">{{notaFiscal.descontoPagamento}}%</b>
                                        </div>
                                    </template>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="my-0 align-self-center">
                                <v-list-item-action-text style="line-height: normal">
                                    Emissão:
                                </v-list-item-action-text>
                                <v-list-item-action-text style="line-height: normal">
                                    <b>{{notaFiscal.createdAt | moment("DD MMM YYYY HH:mm")}}</b>
                                </v-list-item-action-text>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-btn icon :to="{ name: 'nota_fiscal_view', params: { id: parseInt(notaFiscal.id) }}" target="_blank">
                                    <v-icon color="grey lighten-1">mdi-share</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider ></v-divider>
                    </template>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="closeDialog" >
                            Ok
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>

</template>

<script>
import gql from "graphql-tag";

export default {
    name: "MovimentacaoNotasFiscaisDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            notasFiscais: [],
        }
    },
    methods: {
        openDialog(id) {
            this.dialog = true;
            this.notasFiscais = [];
            this.getNotasFiscaisPorMovimento(id)
        },
        closeDialog() {
            this.dialog = false;
        },
        getNotasFiscaisPorMovimento(id){
            this.loading = true;
            this.$apollo.query({
                query : gql`query($id: Int!) {
                    notasFiscais:notasFiscaisPorMovimento(id: $id){
                        id
                        numero
                        dataPagamento
                        valorServicos
                        valorPago
                        descontoPagamento
                        discriminacao
                        createdAt
                    }
                }`,
                variables:{
                    id
                }
            }).then((result) => {
                this.notasFiscais = result.data.notasFiscais;
            }).finally(()=>{
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>