<template>
    <v-dialog v-model="dialog" persistent width="500px">
        <v-card v-if="dialog">
            <v-card-title>{{editMode ? 'Editar' : 'Efetuar'}} Pagamento</v-card-title>
            <v-card-text class="pb-0">
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-menu v-model="dataPagamentoMenu"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="dataPagamentoFormated"
                                        label="Data Pagamento"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="pagamento.dataPagamento"
                                               no-title
                                               locale="pt-br"
                                               @input="dataFiscalizacaoMenu = false">
                                    <!--<v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="dataFiscalizacaoMenu = false">
                                        Cancel
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                                        OK
                                    </v-btn>-->
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-select v-model="pagamento.formaPagamento"
                                      :items="formasPagamento" item-text="nome" return-object
                                      label="Forma de Pagamento" single-line :rules="requiredRules" required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters style="background-color: whitesmoke;">
                        <v-subheader style="width: 100%" class="text-uppercase caption">
                            <span class="blue-grey--text">Comprovante</span>

                            <v-btn v-if="pagamento.arquivos.length === 0" icon x-small color="blue-grey darken-1" class="ml-1"
                                   @click.stop="onAdicionarArquivosButtonClicked">
                                <v-icon small>mdi-plus-circle</v-icon>
                            </v-btn>
                            <input v-show="false" ref="inputFileUpload" type="file"
                                   @input="uploadFileManually" >
                        </v-subheader>
                        <v-list style="width: 100%" dense class="pa-0" color="transparent" v-if="pagamento.arquivos.length > 0">
                            <v-hover v-slot:default="{ hover }">
                                <v-list-item >
                                    <v-list-item-avatar class="my-0" >
                                        <v-icon :style="'opacity: '+(hover?'1':'0.6')"
                                                :color="getIconColor(pagamento.arquivos[0].nome)">
                                            {{getIcon(pagamento.arquivos[0].nome)}}
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content class="py-2">
                                        <v-list-item-title class="font-weight-regular"  :title="pagamento.arquivos[0].nome">
                                            {{pagamento.arquivos[0].nome}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-regular" style="font-size: 10px">
                                            <span v-if="pagamento.arquivos[0].createdAt">Criado em {{pagamento.arquivos[0].createdAt | moment('DD-MM-YYYY [às] HH:mm')}}</span>
                                            <span v-else>Arquivo não salvo ainda</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action class="my-0" v-if="hover" title="">
                                        <div>
                                            <v-btn icon color="red" dark
                                                   @mousedown.stop @touchstart.native.stop
                                                   @click.stop="removeArquivo()">
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-hover>
                        </v-list>
                        <div v-else class="body-2 text-center pa-2 blue-grey--text lighten-2" style="width: 100%">
                            <span class="overline font-weight-medium">Nenhuma arquivo adicionado.</span><br/>
                        </div>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>
                    <v-btn v-if="editMode" text color="red" @click="desfazerPagamento">
                        Desfazer Pagamento
                    </v-btn>

                    <div class="float-right">
                        <v-btn v-if="!editMode" color="primary" text depressed @click="pagar" >
                            Pagar
                        </v-btn>
                        <v-btn v-else color="primary" text depressed @click="pagar" >
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import {getExtensionFile, getIcon, getIconColor} from "../../assets/FileUtils";

export default {
    name: "EfetuarPagamentoDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            form1Valid: true,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            dataPagamentoMenu: false,
            movimento: null,
            editMode: false,
            formasPagamento: [],
            pagamento: {
                dataPagamento: null,
                formaPagamento: null,
                arquivos: [],
            }
        }
    },
    computed:{
        dataPagamentoFormated: {
            get() {
                return this.$moment(this.pagamento.dataPagamento).format('DD/MM/YYYY');
            },
            set(newValue) {
                if (newValue) {
                    this.pagamento.dataPagamento = newValue;
                }
            },
        },
    },
    methods: {
        getIcon, getIconColor, getExtensionFile,
        openDialog(movimento) {
            this.dialog = true;
            this.movimento = movimento;
            if(movimento.pagamento){
                this.editMode = true;
                this.pagamento = this._.cloneDeep(movimento.pagamento);
                this.pagamento.dataPagamento = this.$moment(this.pagamento.dataPagamento).format('YYYY-MM-DD');
                //this.pagamento.arquivos = movimento.pagamento.arquivos
            }else{
                this.editMode = false;
                this.pagamento.dataPagamento = this.$moment(new Date()).format('YYYY-MM-DD');
                this.pagamento.arquivos = [];
            }

            this.getFormasPagamento();
        },
        closeDialog() {
            this.dialog = false;
            this.pagamento =  {
                dataPagamento: null,
                formaPagamento: null,
                arquivos: [],
            }
        },
        getFormasPagamento(){
            this.$apollo.query({
                query : gql`query {
                    formasPagamento{
                        id
                        nome
                    }
                }`,
            }).then((result) => {
                this.formasPagamento = result.data.formasPagamento;
            });
        },
        onAdicionarArquivosButtonClicked(){
            this.$refs.inputFileUpload.value = "";
            this.$refs.inputFileUpload.click();
        },
        uploadFileManually(e){
            if(e.target.files[0].size > 8000000){
                this.$dialog.error({
                    text: 'Arquivo muito grande. Excede o limite máximo de 8mb.',
                    title: 'Ops', actions: {positive: 'ok'}
                });
                return
            }
            this.pagamento.arquivos.push({
                nome: e.target.files[0].name,
                file: e.target.files[0]
            })
        },
        removeArquivo(){
            if(this.pagamento.arquivos[0].id){
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar este arquivo?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: gql`mutation($id: Int!, $arquivoId: Int!) {
                                deletePagamentoArquivo(id: $id, arquivoId: $arquivoId)
                            }`,
                            variables: {
                                id: this.pagamento.id,
                                arquivoId: this.pagamento.arquivos[0].id
                            },
                        }).then(() => {
                            this.pagamento.arquivos = [];
                        })
                    }
                });
            }else{
                this.pagamento.arquivos = [];
            }
        },
        pagar(){
            if (!this.$refs.form1.validate()) {
                return false
            }
            this.loading = true;
            if(!this.editMode){
                this.$apollo.mutate({
                    mutation: gql`mutation($id: Int!, $dataPagamento: LocalDate!, $formaPagamentoId: Int!) {
                        pagamento:efetuarPagamento(id: $id, dataPagamento: $dataPagamento, formaPagamentoId: $formaPagamentoId){
                            id
                            dataPagamento
                            formaPagamento{
                                id
                                nome
                            }
                            movimentacao{
                                id
                            }
                            arquivos{
                                id
                                nome
                                createdAt
                                tags{
                                    id
                                    nome
                                }
                            }
                            ofxTransactions{
                                id
                            }
                        }
                    }`,
                    variables: {
                        id: this.movimento.id,
                        dataPagamento: new Date(this.pagamento.dataPagamento).getTime(),
                        formaPagamentoId: this.pagamento.formaPagamento.id
                    },
                }).then((result) => {
                    let pagamentoSalvo = result.data.pagamento
                    delete pagamentoSalvo.arquivos;
                    this.pagamento = Object.assign(this.pagamento, pagamentoSalvo);
                    if(this.pagamento.arquivos.length > 0){
                        this.uploadFile(this.pagamento).then(()=>{
                            this.loading = false
                            this.$emit('saved', this.pagamento);
                            this.closeDialog();
                        })
                    }else{
                        this.loading = false
                        this.$emit('saved', this.pagamento);
                        this.closeDialog();
                    }
                });
            }else{
                this.$apollo.mutate({
                    mutation: gql`mutation($id: Int!, $dataPagamento: LocalDate!, $formaPagamentoId: Int!) {
                        pagamento:editarPagamento(id: $id, dataPagamento: $dataPagamento, formaPagamentoId: $formaPagamentoId){
                            id
                            dataPagamento
                            formaPagamento{
                                id
                                nome
                            }
                            movimentacao{
                                id
                            }
                            arquivos{
                                id
                                nome
                                createdAt
                                tags{
                                    id
                                    nome
                                }
                            }
                        }
                    }`,
                    variables: {
                        id: this.pagamento.id,
                        dataPagamento: new Date(this.pagamento.dataPagamento).getTime(),
                        formaPagamentoId: this.pagamento.formaPagamento.id
                    },
                }).then((result) => {
                    let pagamentoSalvo = result.data.pagamento

                    if(this.pagamento.arquivos.length > 0 && this.pagamento.arquivos[0].id === undefined){
                        delete pagamentoSalvo.arquivos;
                        this.pagamento = Object.assign(this.pagamento, pagamentoSalvo);
                        this.uploadFile(this.pagamento).then(()=>{
                            this.loading = false
                            this.$emit('saved', this.pagamento);
                            this.closeDialog();
                        })
                    }else{
                        //delete pagamentoSalvo.arquivos;
                        this.pagamento = Object.assign(this.pagamento, pagamentoSalvo);
                        this.loading = false
                        this.$emit('saved', this.pagamento);
                        this.closeDialog();
                    }

                });
            }

        },
        desfazerPagamento(){
            this.$dialog.confirm({
                text: 'Deseja realmente desfazer este pagamento?',
                title: 'Atenção',
            }).then((result) => {
                if (result) {
                    this.$apollo.mutate({
                        mutation: gql`mutation ($id: Int!){
                            desfazerPagamento(id: $id)
                        }`,
                        variables: {
                            id: this.movimento.id
                        },
                    }).then(() => {
                        this.$emit('desfeito', this.movimento)
                        this.closeDialog()
                    });
                }
            });
        },
        uploadFile(pagamento){
            return new Promise(resolve => {
                const formData = new FormData();
                formData.append('file', pagamento.arquivos[0].file);
                this.$axios.post('/financeiro/pagamento/' + pagamento.id + '/uploadFile', formData, {
                    headers: {
                        // @ts-ignore
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    },
                }).then((result) => {
                    this.pagamento.arquivos[0] = result.data;
                    resolve()
                })
            })

        },
    }
}
</script>

<style scoped>

</style>